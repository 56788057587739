import axios from 'axios';
import qs from 'qs';
import { Message } from "element-ui";
import router from "@/router";
const service = axios.create({
  baseURL:process.env.VUE_APP_BASE_URL || '',
  withCredentials: false,
});
//请求拦截器
service.interceptors.request.use(
  (config) => {
    if (config.contentType) {
      config.headers['Content-Type'] = 'application/json; charset=utf-8';
    } else {
      config.headers['Content-Type'] =
        'application/x-www-form-urlencoded; charset=UTF-8';
    }
      const token = sessionStorage.getItem('token');
      if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
          console.log(token);
      }
    if (!config.isbody) {
      config.transformRequest = [
        function (data) {
          data = qs.stringify(data);
          return data;
        },
      ];
    }
    return config;
  },
  () => {
    return Promise.reject();
  }
);
//响应拦截器
service.interceptors.response.use(
  response => {
      if (response.status === 200) {
          if (router.currentRoute.path === '/login') {
              return response.data;
          } else {
              console.log(response.data.code);
              if (response.data.code === 10004) {
                  console.log('帐号已掉线，请重新登录！');
                  Message({
                      message: '帐号已掉线，请重新登录！',
                      type: 'error',
                      duration: 1000
                  });
                  sessionStorage.clear();
                  router.replace('/login');

              } else { //掉线
                  return response.data;
              }
          }
      } else {
        Promise.reject();
      }
  },
  error => {
    Message({
      message: error.response.data[0].message,
      type: "error",
      duration: 1000
    });
    return Promise.reject();
  }
);
export default service;
