import request from '../utils/request';

// const api = 'api/';
const api = ''
//登录
export function userLogin(data) {
    return request({
        url: api+'qx-api/teacher/v1/user/login',
        method: 'POST',
        data,
        contentType: true,
        isbody: true,
    });
}
//获取文字题列表
export function getQuestionObjectiveArray(params) {
    return request({
        url:  api+'qx-api/teacher/v1/question/getQuestionObjectiveArray',
        method: 'GET',
        params,
    });
}
//保存客观题
export function saveObjectiveAns(data) {
    return request({
        url:  api+'qx-api/teacher/v1/question/saveObjectiveAns',
        method: 'POST',
        data,
        contentType: true,
        isbody: true,
    });
}
//获取测评进度
export function getNextQues(params) {
    return request({
        url:  api+'qx-api/teacher/v1/question/getNextQues',
        method: 'GET',
        params,
    });
}
//获取普通文字题列表
export function getQuesDispense(params) {
    return request({
        url:  api+'qx-api/teacher/v1/question/getQuestionObjectiveArrayDispense',
        method: 'GET',
        params,
    });
}
